import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const replaceIpfsUri = (data: string, gatewayUrl = 'https://ipfs.io/ipfs/') => {
  // Original regex for ipfs:// protocol
  const ipfsProtocolRegex = /ipfs:\/\/(?:ipfs\/)?(.+)/;
  const protocolMatch = data.match(ipfsProtocolRegex);

  if (protocolMatch) {
    const ipfsHash = protocolMatch[1];
    return `${gatewayUrl}${ipfsHash}`;
  }

  // New regex for gateway URLs with IPFS hash at the end
  // This matches URLs like https://ipfs.io/ipfs/QmHash or https://gateway.pinata.cloud/ipfs/QmHash
  const gatewayRegex = /https?:\/\/(?:.+)\/ipfs\/([a-zA-Z0-9]*)/;
  const gatewayMatch = data.match(gatewayRegex);

  if (gatewayMatch) {
    const ipfsHash = gatewayMatch[1];

    return `${gatewayUrl}${ipfsHash}`;
  }

  return data;
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
