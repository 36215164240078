import Image from 'next/image';
import { useState } from 'react';

function ImageWithFallback({ src, alt, fallbackSrc, ...props }: any) {
  const [srcErrored, setSrcErrored] = useState(false);

  return (
    <Image
      {...props}
      src={srcErrored ? fallbackSrc : src}
      alt={alt}
      onError={() => {
        console.log('error');
        setSrcErrored(true);
      }}
    />
  );
}

export default ImageWithFallback;
