import { keccak256 } from 'viem';

const PRIME1 = BigInt(1572869);
const PRIME2 = BigInt(29996224275833);

const STROKE_DASH_ARRAYS = ['8, 48', '12, 48', '16, 48', '20, 48', '24, 48', '28, 0'];

export const CUSTOM_THEMES = {
  1: {
    name: 'Coinbase',
    background: '#0052FF',
    road: '#0052FF',
    dash: '#ffffff',
    crashBarrier: '#ffffff',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  2: {
    name: 'Coinbase',
    background: '#E5EDF5',
    road: '#ffffff',
    dash: '#06C1FA',
    crashBarrier: '#06C1FA',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  3: {
    name: 'Coinbase',
    background: '#DBFEE6',
    road: '#DBFEE6',
    dash: '#000000',
    crashBarrier: '#000000',
    textColor: '#000000',
    font: '"Bebas Neue"',
  },
  4: {
    name: 'Coinbase',
    background: '#ffffff',
    road: '#000000',
    dash: '#ffffff',
    crashBarrier: '#D84532',
    textColor: '#000000',
    font: '"Bebas Neue"',
  },
  5: {
    name: 'Coinbase',
    background: '#F8D181',
    road: '#F8D181',
    dash: '#EA8379',
    crashBarrier: '#F2A25A',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },

  6: {
    name: 'Teddies',
    background: '#2C354D',
    road: '#AB5030',
    dash: '#FEB06F',
    crashBarrier: '#7E3839',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  7: {
    name: 'Ghouls',
    background: '#000000',
    road: '#000000',
    dash: '#ffffff',
    crashBarrier: '#A3A3A3',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  8: {
    name: 'Coinbase',
    background: '#000000',
    road: '#000000',
    dash: '#51DC5A',
    crashBarrier: '#51DC5A',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  9: {
    name: 'Mono',
    background: '#f5f5f5',
    road: '#f5f5f5',
    dash: '#2b2b2b',
    crashBarrier: '#2b2b2b',
    textColor: '#2b2b2b',
    font: '"Bebas Neue"',
  },
  10: {
    name: 'Mono',
    background: '#F9F5F1',
    road: '#183A4A',
    dash: '#F8D377',
    crashBarrier: '#8985E3',
    textColor: '#2b2b2b',
    font: '"Bebas Neue"',
  },
  13: {
    name: 'Mono',
    background: '#87E7FD',
    road: '#87E7FD',
    dash: '#ffffff',
    crashBarrier: '#10B3DD',
    textColor: '#2b2b2b',
    font: '"Bebas Neue"',
  },
  14: {
    name: 'Mono',
    background: '#99CCFF',
    road: '#DCDCDC',
    dash: '#ffffff',
    crashBarrier: '#BF6157',
    textColor: '#CCB144',
    font: '"Bebas Neue"',
  },
  15: {
    name: 'Mono',
    background: '#824DA4',
    road: '#281D39',
    dash: '#ffffff',
    crashBarrier: '#ffffff',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  16: {
    name: 'Pizza',
    background: '#FFAF00',
    road: '#BF6157',
    dash: '#ffffff',
    crashBarrier: '#F06400',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  17: {
    name: 'Coinbase',
    background: '#03346d',
    road: '#ffffff',
    dash: '#03346d',
    crashBarrier: '#848884',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  18: {
    name: 'Nordic',
    background: '#a0a0a0',
    road: '#ACDBDF',
    dash: '#C8E8ED',
    crashBarrier: '#6E8291',
    textColor: '#6E8291',
    font: 'Staatliches',
  },
  19: {
    name: 'Mono',
    background: '#FACF3B',
    road: '#FFFFFF',
    dash: '#FFCB00',
    crashBarrier: '#A28839',
    textColor: '#000000',
    font: '"Bebas Neue"',
  },
  20: {
    name: 'Mono',
    background: '#070F25',
    road: '#070F25',
    dash: '#66DFCD',
    crashBarrier: '#66DFCD',
    textColor: '#FFFFFF',
    font: '"Bebas Neue"',
  },
  22: {
    name: 'Mono',
    background: '#04724D',
    road: '#04724D',
    dash: '#FFC300',
    crashBarrier: '#FFC300',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  23: {
    name: 'Mono',
    background: '#F3EAB3',
    road: '#D0A955',
    dash: '#F3EAB3',
    crashBarrier: '#0D3550',
    textColor: '#000000',
    font: '"Bebas Neue"',
  },
  24: {
    name: 'Mono',
    background: '#F8D181',
    road: '#F8D181',
    dash: '#EA8379',
    crashBarrier: '#F2A25A',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  29: {
    name: 'Coinbase',
    background: '#0052FF',
    road: '#0052FF',
    dash: '#ffffff',
    crashBarrier: '#ffffff',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  120: {
    name: 'Coinbase',
    background: '#0052FF',
    road: '#0052FF',
    dash: '#ffffff',
    crashBarrier: '#ffffff',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },

  32: {
    name: 'Dotfan',
    background: '#0C1013',
    road: '#FC394D',
    dash: '#ffffff',
    crashBarrier: '#FE0007',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },

  33: {
    name: 'Mono',
    background: '#000000',
    road: '#FFFFFF',
    dash: '#000000',
    crashBarrier: '#ffffff',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  36: {
    name: 'Valentine',
    background: '#880D1E',
    road: '#DD2D4A',
    dash: '#CBEEF3',
    crashBarrier: '#F26A8D',
    textColor: '#ffffff',
    font: '"Bebas Neue"',
  },
  37: {
    name: 'Mono',
    background: '#000000',
    road: '#FFFFFF',
    dash: '#000000',
    crashBarrier: '#ffffff',
    textColor: '#ffffff',
    font: 'Inter',
  },
  38: {
    name: 'Mono',
    background: '#000000',
    road: '#02C1FA',
    dash: '#ffffff',
    crashBarrier: '#546378',
    textColor: '#ffffff',
    font: 'Orbitron',
  },
  40: {
    name: 'Mono',
    background: '#477DFD',
    road: '#80ABFF',
    dash: '#ffffff',
    crashBarrier: '#F5FDFF',
    textColor: '#ffffff',
    font: "'TT Trailers'",
  },
} as Record<number, any>;

export const THEMES = [
  {
    name: 'Base',
    background: '#23C552',
    crashBarrier: '#105b25', //"#F84F31",
    road: '#C9C9C9',
    dash: '#F2F2F2',
    textColor: '#F2F2F2',
    font: '"Bebas Neue"',
  },
  {
    name: 'Desert',
    background: '#FCF3CC',
    road: '#D6B484',
    dash: '#E9C9A1',
    crashBarrier: '#AF7C54',
    textColor: '#000000',
    font: 'Teko',
  },
  {
    name: 'Cyberpunk',
    background: '#0F2041',
    road: '#D56C8C',
    dash: '#E883A0',
    crashBarrier: '#521D47',
    textColor: '#F2F2F2',
    font: 'Orbitron',
  },

  {
    name: 'Nordic',
    background: '#E9FAFF',
    road: '#ACDBDF',
    dash: '#C8E8ED',
    crashBarrier: '#6E8291',
    textColor: '#6E8291',
    font: 'Staatliches',
  },
  {
    name: 'Mono',
    background: '#f5f5f5',
    road: '#f5f5f5',
    dash: '#2b2b2b',
    crashBarrier: '#2b2b2b',
    textColor: '#2b2b2b',
    font: '"Bebas Neue"',
  },
  {
    name: 'Ringers',
    background: '#f5f5f5',
    road: '#2b2b2b',
    dash: '#f3c845',
    crashBarrier: '#f3c845',
    textColor: '#2b2b2b',
    font: 'Baumans',
  },
];

const FONTS = {
  134: '"Press Start 2P"',
  135: '"Press Start 2P"',
} as Record<string, string>;

function pseudoRandom(seed: number): bigint {
  // Convert the number to a buffer of 32 bytes (256 bits) similar to Solidity's uint256
  const seedBuffer = Buffer.alloc(32); // Create a buffer of 32 bytes filled with zeros
  seedBuffer.writeUInt32BE(seed, 28); // JavaScript uses big-endian, and we write the seed number at the end of the buffer to simulate Solidity's uint256

  // Compute the hash. Note that js-sha3 expects a hexadecimal string or a Uint8Array.
  const hashHex = keccak256(seedBuffer);

  // Convert the hash (in hexadecimal string format) to a BigInt
  const hashedNumber = BigInt(hashHex);

  // Return the hashed number modulo PRIME2
  return hashedNumber % PRIME2;
}

function getThemeObject(id: number) {
  if (id in CUSTOM_THEMES) {
    return CUSTOM_THEMES[id];
  }

  if (id > 130) {
    const seed = Number(pseudoRandom(id) % 1000n);

    if (seed > 900) return THEMES[4];
    if (seed > 750) return THEMES[3];
    if (seed > 550) return THEMES[2];
    if (seed > 300) return THEMES[1];
    return THEMES[0];
  }

  try {
    const seed = Number(pseudoRandom(id) % 1000n);

    if (seed > 990) return THEMES[5];
    if (seed > 900) return THEMES[4];
    if (seed > 850) return THEMES[3];
    if (seed > 750) return THEMES[2];
    if (seed > 600) return THEMES[1]; // should be 600 to match contract
    return THEMES[0];
  } catch (error) {
    console.error(error);
    return THEMES[0];
  }
}

function getStrokeOffset(seed: string) {
  const id = BigInt(seed);
  const offset = 8n + 4n * (id % 5n);
  const gap = id % 69n == 0n ? '0' : '48';
  return `${offset},${gap}`;
}

export function unpackTheme(colors: bigint, id: string) {
  const mask = BigInt(0xffffff);
  return Object.entries({
    background: (colors >> 72n) & mask,
    crashBarrier: (colors >> 48n) & mask,
    road: (colors >> 24n) & mask,
    dash: colors & mask,
  }).reduce(
    (acc, [key, color]) => {
      acc[key] = `#${color.toString(16).padStart(6, '0')}`;
      return acc;
    },
    {
      strokeDashArray: getStrokeOffset(id),
      name: 'Mono',
      font: FONTS[id] || '"Bebas Neue"',
    } as any
  );
}

export function getTheme(id = '0') {
  const theme = getThemeObject(parseInt(id, 10));
  const strokeDashArray = getStrokeOffset(id);
  return {
    ...theme,
    strokeDashArray,
  };
}

const greenFlag = 1704895200;
const maxSupply = 130;
export function currentTrack(): number {
  const secondsSinceDeploy = Math.floor(Date.now() / 1000) - greenFlag;
  const daysSinceDeploy = Math.floor(secondsSinceDeploy / 86400);

  return daysSinceDeploy + 1;
}
